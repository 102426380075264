import { graphql } from "gatsby"
import "twin.macro"
import * as React from "react"
import TextContent from "@molecules/text-content"
import Seo from "@atoms/seo"

const PrivacyPage = ({
  data: {
    ft: { privacy },
  },
}) => (
  <>
    {privacy.seo && <Seo {...privacy.seo} />}
    {privacy.text.map(entry => (
      <TextContent data={entry} />
    ))}
  </>
)

export const query = graphql`
  query PrivacyPage($locale: String) {
    ft {
      privacy(locale: $locale) {
        name
        slug
        seo {
          ...Seo
        }
        text {
          ...TextContent
        }
      }
    }
  }
`
export default PrivacyPage
